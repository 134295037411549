import React from "react";

// import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
// import CleanLayout from "./layouts/Clean";
import CleanXxlLayout from "./layouts/CleanXxl";
import RamsayClinician from "./layouts/RamsayClinician";
import RamsayTLC from "./layouts/RamsayTLC";
// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

import Dashboard from "./pages/ramsay/Dashboard";
import MeetingListPage from "./pages/ramsay/MeetingListPage";
import AsyncPatientViewPage from "./pages/ramsay/AsyncPatientViewPage";
import LiveMeetingPage from "./pages/ramsay/LiveMeetingPage";
import PatientAssesment from "./pages/ramsay/PatientAssesment";
import PageToolbarTest from "./pages/ramsay/PageToolbarTest";
import Management from "./pages/pages/Management";
import PatientTimelineMockPage from "./pages/mocks/PatientTimelineMock";
import PatientViewMockPage from "./pages/mocks/PatientViewMock";
import ChronDashboard from "./pages/mocks/ChronDashboard";
import CaseDetail from "./pages/mocks/CaseDetail";

// const MdtDashboard = async(() => import("./pages/pages/MdtDashboard"));

// element: (
//   <AuthGuard>
//     <CleanXxlLayout />
//   </AuthGuard>
// ),

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <CleanXxlLayout />
      </AuthGuard>
    ),

    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/mdt/:meetingId",
        element: <MeetingListPage />,
      },
      {
        path: "/patient/:patientId",
        element: <AsyncPatientViewPage />,
      },
      {
        path: "/live/:patientId",
        element: <LiveMeetingPage />,
      },
      {
        path: "/assesment/:patientId",
        element: <PatientAssesment />,
      },
      {
        path: "/test",
        element: <PageToolbarTest />,
      },
      {
        path: "/management",
        element: <Management />,
      },
      {
        path: "/chronology",
        element: <PatientTimelineMockPage />,
      },
      {
        path: "/dashboard",
        element: <ChronDashboard />,
      },
      {
        path: "/case/:caseId",
        element: <CaseDetail />,
      },
      {
        path: "/timeline/:patientId",
        element: <PatientViewMockPage />,
      },
    ],
  },
  {
    path: "tlc",
    element: (
      <AuthGuard>
        <RamsayTLC />
      </AuthGuard>
    ),
    children: [
      {
        path: "/tlc",
        element: <Dashboard />,
      },
    ],
  },
  {
    path: "clinician",
    element: (
      <AuthGuard>
        <RamsayClinician />
      </AuthGuard>
    ),
    children: [
      {
        path: "/clinician",
        element: <Dashboard />,
      },
    ],
  },

  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },

  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
